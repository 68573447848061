import './landscape.css'

function isIos(){
  return /(iphone|ipad)/.test(navigator.userAgent.toLowerCase());
}


function LandScapeScreen (){
  return <div className="screen screen--landscape">
           <div className='app-top-wrap'>
              <div className="content-wrap">
                <div className="link-btns">
                    <a className="link-btn link-btn--facebook" href="https://www.facebook.com/supersusgame/"> </a>
                    <a className="link-btn link-btn--discode" href="https://discord.com/invite/supersus"> </a>
                </div>
                <div className='app-top-container'>
                  <div className="app-title"></div>
                  <div className="app-desc">
                      Join your crewmates in a multiplayer game
                  </div>
                </div>
              </div>
              <div className='app-bottom-wrap'>
                  {
                    !isIos() ? (
                      <div className="download-btns--android">
                        <a onClick={window.buryPoint.downlodApk} className="download-btn download-btn--apk" href="https://sg-file.supersus.io/apk/sus64_official_240_1.25.26.1.apk"> </a>
                        <a onClick={window.buryPoint.downlodGoogle} className="download-btn download-btn--google" href="https://play.google.com/store/apps/details?id=com.je.supersus"> </a>
                      </div>
                    ) : 
                    <div className="download-btns--ios">
                      <a onClick={window.buryPoint.downlodApple} className="download-btn download-btn--ios" href="https://apps.apple.com/us/app/super-sus/id1595589498"> </a>
                    </div>
                  }
              </div>
            </div>
          </div>
}

export default LandScapeScreen