import {Enum} from './utils'

const ScreenTypeEnum = new Enum(
  {
    alias: 'PORTRAIT',
    value: 0,
    text: '竖屏'
  },{
    alias: 'LANDSCAPE',
    value: 1,
    text: '横屏'
  }
)

const DeviceTypeEnum = new Enum(
  {
    alias: 'PC',
    value: 0,
    text: 'pc'
  },
  {
    alias: 'MOBILE',
    value: 1,
    text: '手机'
  }
)

export {
  ScreenTypeEnum,
  DeviceTypeEnum
}