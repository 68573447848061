import React from 'react';
import { ScreenTypeEnum } from '../../enums'
import LandScapeScreen from './landscape';
import PortraitScreen from './portrait'

class App extends React.Component{
  constructor(props){
    super(props);
    const screenMode = ScreenTypeEnum.PORTRAIT

    this.state = {
      screenMode,
    }

    this.setRem(screenMode)
  }

  componentDidMount(){
    setTimeout(() => {
      const screenMode = this.setUpScreenMode()
      this.setRem(screenMode)
      console.log('set up screem mode --')
    }, 100)
    
    window.addEventListener('resize', () => {
      const screenMode = this.setUpScreenMode()
      this.setRem(screenMode)
    })
  }



  setRem (screenMode){
    const docEl = document.documentElement
    let { width } = docEl.getBoundingClientRect()
    //let rem = width / 4.14
    if(width > 700){
      width = 700
    }
    let rem = width / 4.14
    if (screenMode === ScreenTypeEnum.LANDSCAPE) {
      rem = width / 7.36
    }
    docEl.style.fontSize = `${rem.toFixed(1)}px`
  }

  setUpScreenMode(){
    const docEl = document.documentElement
    let { width,height } = docEl.getBoundingClientRect()
    let screenMode = ScreenTypeEnum.LANDSCAPE
    if(width >= height){
      screenMode = ScreenTypeEnum.LANDSCAPE
    }else{
      screenMode = ScreenTypeEnum.PORTRAIT
    }
    this.setState({
      screenMode: screenMode
    })
    return screenMode
  }

  render(){
    const {screenMode} = this.state;
    return <div className="App">
      {
        screenMode === ScreenTypeEnum.LANDSCAPE ?
        <LandScapeScreen></LandScapeScreen> :
        <PortraitScreen></PortraitScreen>
      }
    </div>
  }
}

export default App;
