import Enum from './enum'
class BuryPoint {

  downlodApk(){
    window.gtag('event', 'conversion', {'send_to': 'AW-10867875030/qeMvCPz106gDENa5mr4o'});
  }

  downlodApple(){
    window.gtag('event', 'conversion', {'send_to': 'AW-10867875030/76qlCP_106gDENa5mr4o'});
  }

  downlodGoogle(){
    window.gtag('event', 'conversion', {'send_to': 'AW-10867875030/A-xQCIL206gDENa5mr4o'});
  }
  
}

export {
  Enum,
  BuryPoint
}