import './pc.css'
function PcScreen (){
  return <div className="screen screen--pc">
            <div className='app-top-wrap'>
              <div className="content-wrap-auto-width">
                {/* <div className="content-wrap-inner"> */}
                  <div className="content-wrap">
                  <div className="link-btns">
                    <a className="link-btn link-btn--facebook" href="https://www.facebook.com/supersusgame/"> </a>
                    <a className="link-btn link-btn--discode" href="https://discord.com/invite/supersus"> </a>
                  </div>
                  <div className="app-title"></div>
                  <div className="app-desc">
                    Join your crewmates in a multiplayer game
                  </div>
                  </div>
                {/* </div> */}
              </div>
            </div>
            <div className='app-bottom-wrap'>
                <div className="download-btns">
                  <a 
                    className="download-btn download-btn--apk" 
                    href="https://sg-file.supersus.io/apk/sus64_official_240_1.25.26.1.apk"
                    onClick={window.buryPoint.downlodApk}
                  > 
                  </a>
                  <a 
                    className="download-btn download-btn--google" 
                    href="https://play.google.com/store/apps/details?id=com.je.supersus"
                    onClick={window.buryPoint.downlodGoogle}
                  >
                  </a>
                  <a 
                    className="download-btn download-btn--ios" 
                    href="https://apps.apple.com/us/app/super-sus/id1595589498"
                    onClick={window.buryPoint.downlodApple}
                  > 
                  </a>
                </div>
            </div>
          </div>
}

export default PcScreen