import React from 'react';
import { DeviceTypeEnum } from './enums'
import { PcScreen, MobileScreen, abc } from './views'
console.log(PcScreen, MobileScreen, abc)
class App extends React.Component{
  constructor(props){
    super(props);
    const screenMode = this.setUpScreenMode()

    this.state = {
      screenMode,
    }
    this.setUpScreenMode()
  }

  componentDidMount(){
    window.addEventListener('resize', () => {
      this.setUpScreenMode()
    })
  }

  setUpScreenMode(){
    const docEl = document.documentElement
    let { width, height } = docEl.getBoundingClientRect()
    let screenMode = DeviceTypeEnum.PC
    if(width > 900 && height > 680){
      screenMode = DeviceTypeEnum.PC
    }else{
      screenMode = DeviceTypeEnum.MOBILE
    }
    this.setState({
      screenMode
    })
    return screenMode
  }

  render(){
    const {screenMode} = this.state;
    return <>
            {
              screenMode === DeviceTypeEnum.PC ?
              <PcScreen></PcScreen> :
              <MobileScreen></MobileScreen>
            }
          </>
  }
}

export default App;
